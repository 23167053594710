













import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Report',
})
export default class Report extends Vue {
  survey: any = null;

  // HOOKS
  created() {
    const id = this.$route.params.id;
    return this.$store.dispatch('report/getPdf', { id }).then((res) => {
      this.survey = res;
    });
  }
}
